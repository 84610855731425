import React, { useState, useEffect } from 'react';
import axios from 'axios'; // If not installed, install with npm install axios or yarn add axios.
import './LoginPage.css';
import { config } from './config';
import LogoImage from './images/image.png';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState(''); // State to store error message if login fails.
    const navigate = useNavigate();

    useEffect(() => { // This effect runs on initial render only (due to the empty dependency array).
        checkTokenAndRedirect(); // Call a function that checks token and redirects user if all is good.
    }, []); // Empty dependency array ensures this effect runs only once during initial render.

    const checkTokenAndRedirect = async () => {
        const expiryDateStr = localStorage.getItem('dataHive_expiry'); // Get the expiration date string from local storage.
        if (expiryDateStr) { // If it exists, continue to parse and compare with current time.
            const token = localStorage.getItem('dataHive_userToken'); // Retrieve user token stored in local storage as well for API call later on. You should add error handling here!
            if (token) {
                const expiryDate = new Date(expiryDateStr); // Create a date object from the string retrieved above. This allows us to compare it with current time using getTime() method below.
                if (expiryDate > new Date()) { // Compare token's expiration time with current time in milliseconds format and check if it has not passed yet i.e., still valid!
                    try {
                        const response = await axios.get(`${config.apiBaseUrl}${config.endpoints.checkToken}`, { headers: { Authorization: `Bearer ${token}` } }); // Use the token in authorization header for API request to check its authenticity.
                        if (response && response.status === 200) { // If server returns a success status, user is logged in and we can redirect them to dashboard immediately! No need for manual login/sign-up procedure again this time :)
                            navigate('/dashboard');
                        } else {
                            console.error('Token verification failed.'); // Log any other unexpected response or error message here.
                        }
                    } catch (error) {
                        if (error && error.response && error.response.status === 401) { // Handle unauthorized access errors separately by logging them out for security reasons and showing appropriate message to user instead of displaying raw server response which might leak sensitive info about our backend infrastructure/tech stack etc..
                            console.warn('Unauthorized token detected.');
                        } else {
                            console.error(error); // Log other unexpected errors here as well (network errors, server down etc.) for debugging purposes only! Don't display them to user directly due to security concerns and privacy policy compliance issues :)
                        }
                    }
                } else {
                    console.warn('Token expired.'); // If token has already expired based on date comparison above, log a warning message instead of showing it to users as error messages might scare them off or give away sensitive info about our backend infrastructure/tech stack etc.. :)
                }
            }
        } else {
            console.warn('Token not found in local storage.'); // If token doesn't exist in local storage at all, log a warning message instead of showing it to users as error messages might scare them off or give away sensitive info about our backend infrastructure/tech stack etc.. :)
        }
    };

    // Handle form submission. The event object is passed here and its default action (page refresh) is prevented using preventDefault() method.
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let response = await axios({
                url: `${config.apiBaseUrl}${config.endpoints.authenticate}`, // Replace with your own login API endpoint.
                method: 'post',
                data: { username, password },
                headers: { 'Content-Type': 'application/json' },
            });
            console.log(response.data);
            if (response && response.data) { // If successful server returns JWT token or any unique id in response body.
                localStorage.setItem('dataHive_userToken', response.data.token); // Save the user token to local storage for use with future API requests (if required). You can also save it into contextAPI if you're using one like Redux, Mobx etc..
                localStorage.setItem('dataHive_clientIds', response.data.clientIds);
                localStorage.setItem('dataHive_clientId', response.data.clientIds[0]);
                localStorage.setItem('dataHive_clientNames', response.data.clientNames);
                localStorage.setItem('dataHive_clientName', response.data.clientNames[0]);
                localStorage.setItem('dataHive_expiry', response.data.expiry);
                localStorage.setItem('dataHive_authenticated', true);
                localStorage.setItem('dataHive_username', username);
                localStorage.setItem('dataHive_userId', response.data.userId);
                const userKey = btoa(`${username}:${password}`); // Base64 encode the username and password
                localStorage.setItem('datahive_userkey', userKey); // Store the encoded value in localStorage
                navigate('/dashboard'); // Use react-router library and its useHistory hook for redirection after successful login. If not installed install as npm i react-router-dom or yarn add react-router-dom
            } else {
                setErrorMsg('Invalid username or password.'); // Set the error message state if an error occurs during login (incorrect credentials, network errors etc.)
            }
        } catch (error) {
            setErrorMsg('An unexpected error occurred. Please try again later.'); // Handle any unforeseen exceptions here and display a more generic error to user for security reasons.
        }
    };

    return (
        <div className="login-page">{/* Apply the login-page class here */}
            <form onSubmit={handleSubmit} className="login-form"> {/* Apply the login-form class to form element for styling and positioning it in center of screen. */}
                <img src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png" alt="Logo" className='logo' />
                <h1 className="login-heading">DataHive Login</h1>
                <h2 className="login-subheading">Please input your login details to login to DataHive Portal</h2>
                <input type="text" placeholder='Username' onChange={e => setUsername(e.target.value)} className='rounded-input' />
                <input type="password" placeholder='Password' onChange={e => setPassword(e.target.value)} className='rounded-input' /> {/* you should use input field of type password for security reasons */}
                <br></br><br></br><br></br>
                <button onClick={handleSubmit} className="submit-button">Sign In</button> {/* replace 'handleSubmit' with your actual function that handles form submission*/}
                {errorMsg && <p className="error-msg">{errorMsg}</p>}{/* Error message paragraph element*/}
            </form>
        </div>
    );
};
export default LoginPage; // Export the login page component as a module. It can be imported in other components or files for rendering inside another component, route etc..

